import "vue-toastification/dist/index.css";
import '../css/app.css';

import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m';
// import { Ziggy } from './ziggy';
import Toast, { POSITION as TOAST_POSITION} from "vue-toastification";
import * as Sentry from "@sentry/vue";

createInertiaApp({
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) });

        Sentry.init({
            app,
            dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
            environment: import.meta.env.APP_ENV,
            integrations: [
                Sentry.replayIntegration(),
            ],
            // Tracing
            tracesSampleRate: 0.1, //  Capture 100% of the transactions
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
            // Session Replay
            replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        });

        app.use(plugin)
            .use(ZiggyVue, Ziggy)
            .use(Toast, { position: TOAST_POSITION.TOP_RIGHT, timeout: 4000 })
            .mount(el);

        return app;
    },
    progress: {
        color: '#6212E5',
    },
});
